$pageloader-opacity: .8;

// 1. Import the initial variables
@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";

@import 'bulma/bulma';
@import 'bulma-extensions/src/sass';

// 3. Add new color variables to the color map.
@import "bulma/sass/utilities/derived-variables";

// 2. Setup your Custom Colors
$purple: hsl(275,87%,45%);
$purple-invert: findColorInvert($purple);

$addColors: (
  "purple":($purple, $purple-invert),
);

$colors: map-merge($colors, $addColors);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
